<script setup>
import { ref } from 'vue'

const count = ref(0)
const titleClass = ref('title')

function increment() {
  count.value++
}
</script>

<template>
<h1 :class="titleClass">Click the button</h1>
  <button @click="increment">Count is: {{ count }}</button>
</template>

<style>
.title {
  color: navy;
}
</style>